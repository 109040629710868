import React from "react";

import { Box, Button, Typography } from "@mui/material";
import QRCode from "qrcode";

interface BankIdMobileLoginQrProps {
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loginFailed: boolean;
  qrCode: string | null;
  userMessage: string;
  logInAgain: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void | Promise<void>;
}

const BankIdMobileLogin: React.FC<BankIdMobileLoginQrProps> = ({
  onBackClick,
  loginFailed,
  qrCode,
  userMessage,
  logInAgain,
}: BankIdMobileLoginQrProps) => {
  const qrCodeCanvasRef = React.useRef(null);

  React.useEffect(() => {
    if (qrCodeCanvasRef?.current === null) return;
    if (qrCode === null || qrCode == "") {
      // todo added login or wait text.
      return;
    }
    QRCode.toCanvas(qrCodeCanvasRef.current, qrCode, {
      width: 212,
    });
  }, [qrCode, qrCodeCanvasRef]);

  return (
    <>
      <Box
        component="img"
        src="/images/BankID_logo.svg"
        alt="BankID Logo"
        sx={{
          width: 140,
          height: 140,
          ml: "auto !important", // for some reason, the Stack element's margin will overwrite these
          mr: "auto !important",
          mt: "0 !important",
          mb: "0 !important",
          display: "block",
        }}
      />
      {loginFailed ? (
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {userMessage}
        </Typography>
      ) : (
        <Box
          component="canvas"
          id="qrcode"
          sx={{
            minHeight: 212,
            minWidth: 212,
            width: 212,
            height: 212,
            ml: "auto !important", // for some reason, the Stack element's margin will overwrite these
            mr: "auto !important",
            mt: "0px !important",
            display: "block",
            borderRadius: 6,
            borderStyle: "solid",
            borderColor: "#D1DFF1",
          }}
          ref={qrCodeCanvasRef}
          aria-label={"QR-kod för inloggning med BankID"}
        />
      )}
      <Box
        sx={{
          width: "90%",
          textAlign: "center",
          m: "auto !important",
          mt: "16px !important",
          mb: "16px !important",
        }}
      >
        {!loginFailed && (
          <Typography
            sx={{
              fontStyle: "normal",
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Starta din BankID-app och tryck “Skanna QR-kod”. Rikta kameran mot
            QR-koden här ovanför för att logga in.
          </Typography>
        )}
        {loginFailed && (
          <Button
            variant="contained"
            fullWidth
            onClick={logInAgain}
            disableRipple
            sx={{
              width: {
                md: "328px",
              },
              left: "50%",
              transform: "translateX(-50%)",
              mt: "16px",
              mb: 0,
              borderRadius: 6,
              height: 48,
              fontWeight: 700,
              fontSize: 14,
              lineHeight: "16px",
              letterSpacing: 1,
              textTransform: "uppercase",
              boxShadow: "unset",
              "&:hover": {
                color: "#FFFFFF",
                backgroundColor: "secondary.main",
                boxShadow: "unset",
              },
              "&:active": {
                backgroundColor: "#275284",
                boxShadow: "unset",
              },
            }}
          >
            logga in
          </Button>
        )}
      </Box>
      {/* cancel btn for mobile */}
      <Button
        variant="text"
        fullWidth
        disableRipple
        color="secondary"
        sx={{
          display: {
            xs: "inline-flex",
            md: "none",
          },
          mt: 0.5,
          borderColor: "#D1DFF1",
          borderRadius: 6,
          height: 48,
          fontWeight: 700,
          fontSize: 14,
          textDecoration: "underline",
          lineHeight: "16px",
          letterSpacing: 1,
          textUnderlineOffset: 4,
          color: "primary.main",
          "&:hover": {
            textDecoration: "underline",
          },
          "&:active": {
            backgroundColor: "#275284",
            textDecoration: "underline",
            boxShadow: "unset",
          },
        }}
        onClick={onBackClick}
      >
        Avbryt
      </Button>
      {/* cancel btn for desktop */}
      <Button
        variant="text"
        fullWidth
        disableRipple
        sx={{
          display: {
            xs: "none",
            md: "inline-flex",
          },
          mt: 0.5,
          borderRadius: 6,
          textDecoration: "underline",
          height: 48,
          fontWeight: 700,
          fontSize: 14,
          lineHeight: "16px",
          letterSpacing: 1,
          textUnderlineOffset: 4,
          color: "primary.main",
          "&:hover": {
            color: "#FFFFFF",
            textDecoration: "underline",
            backgroundColor: "secondary.main",
          },
          "&:active": {
            backgroundColor: "#275284",
            textDecoration: "underline",
            boxShadow: "unset",
          },
        }}
        onClick={onBackClick}
      >
        Avbryt
      </Button>
    </>
  );
};

export default BankIdMobileLogin;
