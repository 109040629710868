import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export type EnvResponse = {
  faro_endpoint: string;
  faro_key: string;
  env: string;
  version: string;
  tracer_name: string;
  is_only_webapp: string;
  back_url: string;
};

const EnvContext = createContext({
  ready: false,
  env: {} as Partial<EnvResponse>,
});

export const EnvironmentProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [response, setResponse] = useState<Partial<EnvResponse>>({});
  const [ready, setReady] = useState(false);
  useEffect(() => {
    fetch("/env.json")
      .then((res) => res.json())
      .then(setResponse);
  }, []);

  useEffect(() => {
    if (Object.keys(response).includes("env")) setReady(true)
  }, [response])

  return (
    <EnvContext.Provider value={{ ready, env: response }}>
      {children}
    </EnvContext.Provider>
  );
};

export const useEnv = () => useContext(EnvContext);
