import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import AuthRoot from "./AuthRoot";
import { BankIdProvider } from "./shared";
import { getLoginMethodFromLoginHint, isMobile } from "./shared/utils";
import { EnvironmentProvider } from "./shared/provider/EnvironmentProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0D3050",
    },
    secondary: {
      main: "#1C5DAC",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: ["proxima-nova"].join(","),
    fontWeightRegular: 400,
    allVariants: {
      color: "#0D3050",
    },
  },
});

const App: React.FC<{}> = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const loginChallenge = searchParams.get("login_challenge");
  const loginHint = searchParams.get("login_hint") || "";
  return (
    <React.StrictMode>
      <EnvironmentProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={new QueryClient({})}>
            <BankIdProvider
              apiUrl=""
              loginChallenge={loginChallenge}
              loginHint={getLoginMethodFromLoginHint(
                loginHint,
                isMobile(window.navigator.userAgent)
              )}
            >
              <AuthRoot />
            </BankIdProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </EnvironmentProvider>
    </React.StrictMode>
  );
};

export default App;
