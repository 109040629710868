import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import React, { FC } from "react";
import { desktopLoginSelectorStyle, mobileLoginSelectorStyle } from "../../Styles";

export const ToggleMode: FC<{
    loginMethod: string;
    onChange: (event: any, nv: string) => void;
  }> = ({ loginMethod, onChange }) => {
    return (
      <>
        <ToggleButtonGroup
          color="primary"
          value={loginMethod}
          onChange={onChange}
          exclusive
          fullWidth
          sx={desktopLoginSelectorStyle}
        >
          <ToggleButton
            disableRipple={true}
            disableTouchRipple={true}
            value="this_device"
          >
            denna enhet
          </ToggleButton>
          <ToggleButton
            disableRipple={true}
            disableTouchRipple={true}
            value="mobile"
          >
            annan enhet
          </ToggleButton>
        </ToggleButtonGroup>
        {/* btn group for mobile */}
        <ToggleButtonGroup
          color="primary"
          value={loginMethod}
          onChange={onChange}
          exclusive
          fullWidth
          sx={mobileLoginSelectorStyle}
        >
          <ToggleButton
            disableRipple={true}
            disableTouchRipple={true}
            value="this_device"
          >
            denna enhet
          </ToggleButton>
          <ToggleButton
            disableRipple={true}
            disableTouchRipple={true}
            value="mobile"
          >
            annan enhet
          </ToggleButton>
        </ToggleButtonGroup>
      </>
    );
  };
  