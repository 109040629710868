import React, { useCallback, useEffect, useMemo } from "react";

import {
  Card,
  CardContent,
  Container,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import BankIdMobileLoginQr from "./BankIdMobileLoginQr";
import BankIdThisDeviceLogin from "./BankIdThisDeviceLogin";
import { loginTitleStyle, mainCardStyle } from "./Styles";
import { LoginMethod, useBankId } from "./shared";
import { ToggleMode } from "./shared/components/ToggleMode";
import { useBankIdFlow } from "./shared/hooks/useBankIdFlow";
import { hintCodeMessage, resolveMessage } from "./texts";
import { useEnv } from "./shared/provider/EnvironmentProvider";

interface BankIdFrameProps {
  sx?: SxProps<Theme>;
}

const BankIdFrame: React.FC<BankIdFrameProps> = ({ sx = [] }) => {
  const { ready, env } = useEnv();
  const { loginMethod, setLoginMethod } = useBankId()!;
  const { mode, startFlow, isQR, qrData, status, isBankIdAppOpen } =
    useBankIdFlow();

  const navBack = useCallback(() => {
    if (!ready) {
      window.history.back();
      return;
    }
    const { back_url } = env;
    if (!back_url) {
      window.history.back();
      return;
    }
    window.location.assign(back_url);
  }, [ready]);

  const userMessage = useMemo(() => {
    if (!status) return "";
    if (status.status == "failed")
      return resolveMessage(hintCodeMessage, status.hintCode);
    return "";
  }, [status?.hintCode]);

  const timeoutMessage =
    "Tiden för att läsa av QR-koden har gått ut. Försök att logga in igen. Om det inte funkar prova att uppdatera BankID-appen på din telefon.";

  const clearCache = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log("Cache Cleared");
  };

  return (
    <Container sx={{ minHeight: "calc(100vh - 450px)" }}>
      <Card variant="elevation" sx={mainCardStyle} elevation={0}>
        <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
          <Stack spacing={3}>
            <Typography sx={loginTitleStyle}>Logga in</Typography>
            <ToggleMode
              loginMethod={loginMethod}
              onChange={(_, nv) => {
                setLoginMethod(nv as LoginMethod);
                if (loginMethod == "this_device") startFlow();
              }}
            />
            {isQR ? (
              <BankIdMobileLoginQr
                onBackClick={() => Promise.resolve(navBack())}
                loginFailed={mode === "failed"}
                qrCode={qrData?.qrCode || ""}
                userMessage={timeoutMessage}
                logInAgain={() => Promise.resolve(navBack())}
              />
            ) : (
              <BankIdThisDeviceLogin
                bankIdOpened={isBankIdAppOpen}
                loginFailed={mode === "failed"}
                startBankIdLogin={startFlow}
                onBackClick={() => Promise.resolve(navBack())}
                userMessage={userMessage}
              />
            )}
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default BankIdFrame;
