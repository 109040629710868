export const defaultUserMessage = "Okänt fel. Försök igen." as const;

export const hintCodeMessage = {
  userCancel: "Åtgärden avbruten.",
  transactionRiskBlocked:
    "För hög risk. BankID tycker att din koppling är inte helt säker byt nätverk och försök igen.",
  expiredTransaction:
    "BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din bank. Försök sedan igen.",
  certificateErr:
    "Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.",
  startFailed:
    "Misslyckades att läsa av QR koden. Starta BankID-appen och läs av QR koden. Kontrollera att BankID-appen är uppdaterad. Om du inte har BankID-appen måste du installera den och hämta ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.",
} as const;

export const errorCodeMessage = {
  cancelled: "Åtgärden avbruten. Försök igen.",
  alreadyInProgress:
    "En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.",
  requestTimeout: "Internt tekniskt fel. Försök igen.",
  maintenance: "Internt tekniskt fel. Försök igen.",
  internalError: "Internt tekniskt fel. Försök igen.",
} as const;

export const resolveMessage = <T>(
  messages: Record<keyof T, string>,
  key: string
) => {
  if (key in messages) {
    return messages[key as keyof T];
  }
  return defaultUserMessage;
};
