export const mainCardStyle = {
  boxSizing: "border-box",
  width: {
    xs: "342px",
    md: "360px",
  },
  p: {
    xs: "24px 16px 16px 16px",
    md: "24px 16px 16px 16px",
  },
  borderRadius: "8px",
  m: {
    xs: "32px 0 32px 0",
    md: "40px 0 40px 0",
  },
  position: "relative",
  left: "50%",
  transform: "translateX(-50%)",
};

export const loginTitleStyle = {
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xs: "32px",
    md: "48px",
  },
  lineHeight: "36px",
  textAlign: "center",
};

export const desktopLoginSelectorStyle = {
  display: {
    xs: "none",
    md: "inline-flex",
  },
  height: "56px",
  background: "#EEF2F8",
  borderRadius: 6,
  "& .MuiToggleButtonGroup-grouped, & .MuiToggleButtonGroup-grouped:not(:first-of-type), & .MuiToggleButtonGroup-grouped:not(:last-of-type)":
    {
      color: "primary.main",
      m: 1,
      borderRadius: 6,
      borderWidth: 0,
      fontStyle: "normal",
      textTransform: "uppercase",
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "24px",
      letterSpacing: "1px",
      "&:hover": {
        backgroundColor: "secondary.main",
        color: "#FFFFFF",
      },
      "&:active": {
        backgroundColor: "#275284",
      },
      "&.Mui-selected": {
        backgroundColor: "primary.main",
        color: "#FFFFFF",
      },
      "&.Mui-selected:hover": {
        backgroundColor: "secondary.main",
        color: "#FFFFFF",
      },
      "&.Mui-selected:active": {
        backgroundColor: "#275284",
      },
    },
};
export const mobileLoginSelectorStyle = {
  display: {
    md: "none",
  },
  height: "56px",
  background: "#EEF2F8",
  borderRadius: 6,
  "& .MuiToggleButtonGroup-grouped, & .MuiToggleButtonGroup-grouped:not(:first-of-type), & .MuiToggleButtonGroup-grouped:not(:last-of-type)":
    {
      color: "primary.main",
      m: 1,
      borderRadius: 6,
      borderWidth: 0,
      fontStyle: "normal",
      textTransform: "uppercase",
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "24px",
      letterSpacing: "1px",
      "&:active": {
        backgroundColor: "#275284",
      },
      "&.Mui-selected": {
        backgroundColor: "primary.main",
        color: "#FFFFFF",
      },
      "&.Mui-selected:active": {
        backgroundColor: "#275284",
      },
    },
};
